import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Typography } from '@mui/material';
import InterestingLists from 'components/pages/main/interests/InterestingLists';
import navigationRoutes from 'constants/navigationRoutes';
import Link from 'next/link';
import { fetchInterestsLists as fetchInterestsListsAPI } from '@api/listAPI';
import ListsStub from '../ListsStub';

function InterestsAuthorised() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [myInterests, setMyInterests] = useState();

  useEffect(() => {
    setLoading(true);
    fetchInterestsListsAPI({ limit: 8 }).then(({ data }) => {
      setMyInterests(data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="flex justify-start items-center">
        <Typography variant="h1" className="pr-4">
          {t('mainPage:interestingListsMainPage')}
        </Typography>
        <Link shallow={true} prefetch={false} href={navigationRoutes.INTERESTS_PAGE.path}>
          <Typography
            variant="body1"
            color="inherit"
            className="underline text-secondary hover:text-primary-main"
          >
            {t('mainPage:linkToAllMainPage')} {'>'}
          </Typography>
        </Link>
      </div>
      {loading
      ? <ListsStub />
      : <InterestingLists interestLists={myInterests} />}
    </div>
  );
}

export default InterestsAuthorised;
