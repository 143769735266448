import React from 'react';
import { isEmpty } from 'lodash';
import InterestsAuthorised from 'components/pages/main/interests/InterestsAuthorised';

function Interests({ userToken }) {
  return isEmpty(userToken)
      ? null
      : <InterestsAuthorised />;

}

export default Interests;
