import React, { useEffect, useState } from 'react';
import Card from '@ui-kit/components/Card/Card';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import Typography from '@mui/material/Typography';
import { getUserByLogin as getUserByLoginAPI } from '@api/userAPI';
import useFormattedLocalDateTime from '@ui-kit/hooks/useFormattedLocalDateTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import listImg from './list.png';

function ForeignListCard({ list }, ref) {
  const { t } = useTranslation();
  const [authorName, setAuthorName] = useState('');

  useEffect(() => {
    if (!isEmpty(list?.userLogin)) {
      getUserByLoginAPI(list?.userLogin).then(response => {
        setAuthorName(response?.data?.displayName ?? list?.userLogin ?? '');
      });
    }
  }, [list?.userLogin]);

  const caption = React.useMemo(() => {
    return isEmpty(authorName)
      ? null
      : (
        <Typography variant="caption" color="text.secondary" className="clamp-text-1 text-left">
          {t('fromAuthor')} {authorName}
        </Typography>
      );
  }, [t, authorName]);

  const date = useFormattedLocalDateTime(list?.eventDate);
  const actions = React.useMemo(() => {
    return [
      <div key="date" className="flex gap-1 items-center text-secondary h-6">
        {!isEmpty(date) && <AccessTimeIcon fontSize="inherit" />}
        <Typography variant="caption">
          {date}
        </Typography>
      </div>,
    ];
  }, [date]);

  return (
    <Card
      ref={ref}
      title={list?.title}
      description={list?.description ?? ''}
      caption={caption}
      actions={actions}
      oneLineDescription={true}
      imageUrl={list?.image}
      defaultImgUrl={listImg.src}
    />
  );
}

export default React.forwardRef(ForeignListCard);
