import axios from '@api/axiosInstance';
import { getCookie } from 'cookies-next';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';
import { formatDateForSaving } from '@utils/dateTimeUtils';
import isEmpty from 'lodash/isEmpty';

export function fetchList({ userToken, listId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(`/api/lists/byId?listId=${listId ?? ''}&token=${token}`);
}

export function fetchLists({ userToken, periodFilterShortcut, limit, offset }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(
    `/api/lists?periodFilterShortcut=${periodFilterShortcut ?? ''}&limit=${limit ?? ''}&offset=${offset ??
    ''}&token=${token}`,
  );
}

export function fetchInterestsLists({ userToken, periodFilterShortcut, limit, offset }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(
    `/api/lists/interests?periodFilterShortcut=${periodFilterShortcut ?? ''}&limit=${limit ?? ''}&offset=${offset ??
    ''}&token=${token}`,
  );
}

export function postList({ userToken, list }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.post(`/api/lists?token=${token}`, {
    listId: list?.listId ?? null,
    title: list?.title ?? '',
    description: list?.description ?? '',
    image: list?.image ?? '',
    type: list?.type ?? null,
    userLogin: list?.userLogin ?? '',
    isPublished: list?.isPublished ?? false,
    reservationEnabled: list?.reservationEnabled ?? false,
    reservationVisible: list?.reservationVisible ?? false,
    eventDate: formatDateForSaving(list?.eventDate),
    createdAt: formatDateForSaving(list?.createdAt),
  });
}

export function deleteList({ userToken, listId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.delete(`/api/lists?listId=${listId}&token=${token}`);
}
