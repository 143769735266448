import React, { useCallback, useEffect, useState } from 'react';
import MuiCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import noImage from './icon-no-image.svg';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

function Card({
  title,
  caption,
  imageUrl,
  defaultImgUrl = noImage.src,
  description,
  oneLineDescription,
  floatingIcon,
  actions,
  className,
}, ref) {
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    setImgSrc(isEmpty(imageUrl) ? defaultImgUrl : imageUrl);
  }, [imageUrl, defaultImgUrl]);

  const handleImgError = useCallback(() => {
    setImgSrc(defaultImgUrl);
  }, [defaultImgUrl]);

  return (
    <MuiCard className={clsx(className, 'rounded-[20px] overflow-hidden')} ref={ref}>
      <CardMedia
        component="img"
        image={imgSrc}
        alt={title ?? ''}
        title={title ?? ''}
        onError={handleImgError}
        className="h-36"
      />
      <CardContent className="h-32 max-h-32 gap-y-2 flex flex-col">
        <div className="flex gap-x-2 items-center">
          <Typography variant="subtitle1" component="div" className="clamp-text-1 text-left">
            {title ?? ''}
          </Typography>
          {floatingIcon}
        </div>
        {description !== undefined &&
          <Typography
            variant="caption"
            color="text.secondary"
            className={`text-left ${oneLineDescription
              ? 'min-h-[1.25rem] clamp-text-1'
              : 'min-h-[2.5rem] clamp-text-2'
            }`}
          >
            {description}
          </Typography>
        }
        {caption ?? null}
      </CardContent>
      {actions &&
        <CardActions className="px-4 pt-0 pb-6">
          {actions}
        </CardActions>
      }
    </MuiCard>
  );
}

export default React.forwardRef(Card);
