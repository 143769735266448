import { useMemo } from 'react';
import { momentFromLocalDateTime } from '@utils/dateTimeUtils';
import { useTranslation } from 'next-i18next';

function useFormattedLocalDateTime(localDateTime) {
  const { t } = useTranslation();

  return useMemo(() => {
    const momentObj = momentFromLocalDateTime(localDateTime);
    return momentObj?.format?.('Do MMMM YYYY') ?? t('dateless');
  }, [t, localDateTime]);
}

export default useFormattedLocalDateTime;
