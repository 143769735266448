import React from 'react';
import map from 'lodash/map';
import Card from '@ui-kit/components/Card/Card';

function ListsStub() {
  return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {map('123', (_, idx) => (
            <Card
                key={idx}
                className="animate-pulse"
            />
        ))}
      </div>
  );
}

export default React.memo(ListsStub);
