import moment from '@utils/momentInstance';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

export const momentFromLocalDateTime = function(localDateTime) {
  if (isString(localDateTime)) {
    const momentObj = moment(localDateTime);
    return momentObj.isValid() && momentObj.year() !== 1970
      ? momentObj
      : null;
  } else {
    return localDateTime && localDateTime?.year !== 1970
      ? moment({
        year: localDateTime?.year,
        month: localDateTime?.monthValue - 1,
        day: localDateTime?.dayOfMonth,
      })
      : null;
  }
};

export const localDateTimeFromMoment = function(momentObj) {
  return momentObj?.isValid() === true
    ? momentObj.toISOString(true).split('.')[0]
    : null;
};

export const formatDateForSaving = function(date) {
  if (isNil(date)) {
    return null;
  }
  if (isString(date)) {
    return date;
  }
  if (date?.isValid?.() === true) {
    return localDateTimeFromMoment(date);
  }
  return localDateTimeFromMoment(momentFromLocalDateTime(date));
};
