import React from 'react';
import ForeignListCard from '@ui-kit/components/Card/list/ForeignListCard';
import navigationRoutes from 'constants/navigationRoutes';
import Link from 'next/link';
import map from 'lodash/map';

function InterestingLists({ interestLists }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {map(interestLists, (interestingList, idx) => (
        <Link
          key={`${idx}_${interestingList?.listId}`}
          shallow={true}
          prefetch={false}
          href={{
            pathname: `${navigationRoutes.INTERESTS_PAGE.path}/[listId]`,
            query: { listId: interestingList.listId },
          }}
          className="card-shadow rounded-[20px]"
        >
          <ForeignListCard
            key={`${idx}_${interestingList?.title}`}
            list={interestingList}
          />
        </Link>
      ))}
    </div>
  );
}

export default InterestingLists;
